<template>
  <v-card
    class="mb-2 page__container "
    :class="activeEmail && activeEmail.id === email.id ? 'active__page' : ''"
  >
    <v-card-text class="py-4">
      <div class="d-flex flex-wrap">
        <div
          class="page d-flex py-2  "
          style="flex:1"
          @click="setActiveEmail(email)"
        >
          <div
            class="d-flex  align-start"
          >
            <v-img
              width="50"
              class="mr-4"
              :src="`https://www.google.com/s2/favicons?domain=${email.url}&sz=256`"
            ></v-img>
          </div>
          <div class="regular-12">
            <h3 class="page__title">
              {{ email.pivot.name }}
              <v-tooltip
                v-if="email.versions_count < 1"
                right
                close-delay="5000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    color="rgba(58, 53, 65, 0.54);"
                    v-on="on"
                  >
                    {{ icons.mdiAt }}
                  </v-icon>
                </template>
                <span
                  @click="copyToClipboard(email.pivot.email_address);"
                >{{ email.pivot.email_address }} <v-icon
                  size="17"
                  class="ml-1 pointer"
                  color="rgb(225 235 234)"
                >{{ icons.mdiContentCopy }}</v-icon></span>
              </v-tooltip>
            </h3>
            {{ email.url }}
          </div>
        </div>

        <div class=" d-flex mt-2">
          <div
            class="actions d-flex   "
            style="min-width:10px"
          >
            <div>
              <v-btn
                icon
                small
                @click="editPage(email)"
              >
                <v-icon size="19">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                small
                @click="deleteConfirmation(email)"
              >
                <v-icon size="19">
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex cursor-pointer"
        @click="setActiveEmail(email)"
      >
        <div style="width:70px"></div>
        <div class="w-100  d-flex align-baseline justify-space-between">
          <v-chip
            v-if="email.versions_count"
            class="info__chip"
          >
            +{{ email.versions_count }}
          </v-chip>
          <v-chip
            v-else
            class="info__chip-white"
          >
            No Emails
          </v-chip>
          <span class="text--primary">{{ email.workflow }}</span>
          <span class="text--disabled">{{ moment(email.pivot.created_at).format('DD.MM.YYYY') }}</span>
        </div>
      </div>
    </v-card-text>
    <div
      v-if="activeEmail && activeEmail.id === email.id"
      class="active__border"
    >
      <v-icon color="white">
        {{ icons.mdiChevronRight }}
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import {
  mdiAt,
  mdiChevronRight,
  mdiClock,
  mdiContentCopy,
  mdiDelete,
  mdiDotsVertical,
  mdiMonitor,
  mdiPauseCircleOutline,
  mdiPencilOutline,
  mdiPlayCircleOutline,
} from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  props: {
    email: {
      type: Object,
      required: true,
    },
    deleteConfirmation: {
      type: Function,
      required: true,
    },
    editPage: {
      type: Function,
      required: true,
    },
  },
  setup(props, { root }) {
    const icons = {
      mdiDotsVertical,
      mdiClock,
      mdiPauseCircleOutline,
      mdiPlayCircleOutline,
      mdiDelete,
      mdiPencilOutline,
      mdiMonitor,
      mdiChevronRight,
      mdiAt,
      mdiContentCopy,
    }

    const activeEmail = computed(() => root.$store.state.tools.emailSpy.activeEmail)

    const setActiveEmail = item => {
      root.$store.dispatch('tools/emailSpy/setActiveEmail', item)
    }

    return {
      icons,
      setActiveEmail,
      activeEmail,
      copyToClipboard: text => {
        navigator.clipboard.writeText(text)
        root.$store.dispatch('notify', 'Email copied to clipboard')
      },
    }
  },
}
</script>
<style>
.v-tooltip__content {
  pointer-events: auto;
}
</style>
<style scoped lang="scss">
.page__container {
  display: flex;

  .info__chip {
    color: #ff4c51;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    background: #fee8e7;
  }
  .info__chip-white {
    color: #3a354161;
    // background: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .active__border {
    width: 30px;
    background: #c81043;
    border: 1px solid #c81043;
    display: flex;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .page {
    cursor: pointer;
    .icon-circle {
      border-radius: 50%;
      padding: 0.6rem;
      background: #f7e1e7;
    }
    .page__title {
      color: rgba(58, 53, 65, 0.87);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.15px;
    }
  }
  .meta-info {
    font-size: 12px;
  }
  label {
    color: #3a354161;
  }

  .actions {
    .v-icon {
      color: rgba(58, 53, 65, 0.32);
    }
    .page__status {
      background: rgba(255, 76, 81, 0.2);
      margin-bottom: 5px;
      height: 29px;
      color: #ff4c51;
    }
  }
}

.active__page {
  border: 1px solid #c81043;
  box-shadow: none !important;
}
.pointer {
  cursor: pointer;
}
</style>
