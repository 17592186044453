<template>
  <v-card :loading="loading">
    <v-card-title
      v-if="searchEnabled"
      class="card__title search__container"
    >
      <v-text-field
        v-model="searchKeyword"
        :prepend-inner-icon="icons.mdiMagnify"
        hide-details
        placeholder="Search mail"
        outlined
        dense
        @change="fetchVersions"
      ></v-text-field>
      <v-icon @click="searchEnabled = false">
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <v-card-title
      v-else-if="activeEmail || starredMails"
      class="word-break-inherit flex-nowrap card__title"
    >
      <v-icon
        class="pointer mr-2"
        @click="setActiveEmail(null)"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      <div
        class="card--title"
      >
        {{ activeEmail ? activeEmail.pivot.name : 'All Starred Emails' }}

        <v-icon
          style="position:absolute;right:20px"
          class="pointer"
          @click="searchEnabled = true"
        >
          {{ icons.mdiMagnify }}
        </v-icon>
      </div>
    </v-card-title>

    <v-card-title
      v-else
      class="card--title card__title"
    >
      Activity Timeline
      <v-spacer></v-spacer>
      <v-icon
        class="pointer"
        style="position:absolute;right:20px"
        @click="searchEnabled= true"
      >
        {{ icons.mdiMagnify }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text v-if="emailVersions && emailVersions.data.length">
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots"
        :class="activeEmail ? '' : 'timeline__removeLine'"
      >
        <v-timeline-item
          v-for="(version,key) in emailVersions.data"
          :key="key"
          small
          :color="resolveColor(version.spy_email_id,key)"
        >
          <div
            class="d-flex align-center align-baseline flex-wrap"
          >
            <div>
              <h5 class="medium-16 mb-2">
                {{ version.subject }}
              </h5>
              <span>{{ version.from.email }}</span>
            </div>
            <v-spacer></v-spacer>
            <div>
              <div class="mb-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#3A354161"
                      v-bind="attrs"
                      v-on="on"
                      @click="starredSpyEmail(version)"
                    >
                      {{ version.is_starred ? icons.mdiStar : icons.mdiStarOutline }}
                    </v-icon>
                  </template>
                  <span>Starred Email</span>
                </v-tooltip>

                <v-icon
                  size="20"
                  color="#3A354161"
                  class="mx-2"
                >
                  {{ icons.mdiOpenInNew }}
                </v-icon>
                <v-icon
                  size="20"
                  color="#3A354161"
                >
                  {{ icons.mdiShareVariant }}
                </v-icon>
              </div>
              <span class="text-no-wrap ">{{ moment(version.created_at).format('DD.MM.YYYY') }}</span>
            </div>
          </div>
          <img
            style="max-width:100%;"
            :src="__serverImage(version.screenshot_path)"
            class="preview__image mt-4"
          />
          <!-- <iframe
            style="width:100%"
            height="550"
            :src="resolveIframeURL(version.id)"
            frameborder="0"
          ></iframe> -->
        </v-timeline-item>
      </v-timeline>
      <div
        v-if="emailVersions.next_page_url"
        class="text-center mt-4"
      >
        <v-btn
          :loading="loading"
          @click="fetchMoreVersions"
        >
          Load more
        </v-btn>
      </div>
    </v-card-text>

    <v-card-text v-else>
      <no-content-found></no-content-found>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiArrowLeft, mdiClose, mdiMagnify, mdiOpenInNew, mdiShareVariant, mdiStar, mdiStarOutline } from '@mdi/js'
import { computed, reactive } from '@vue/composition-api'
import { mapActions } from 'vuex'
import NoContentFound from './NoContentFound.vue'

export default {
  components: {
    NoContentFound,
  },

  setup(props, { root }) {
    const $store = root.$store.state.tools.emailSpy
    const activeEmail = computed(() => $store.activeEmail)
    const starredMails = computed(() => $store.starredMails)
    const loading = computed(() => $store.loading.timeline)
    const emailVersions = computed(() => $store.versions)

    const searchEnabled = computed({
      get() {
        return $store.search.enabled
      },
      set(val) {
        $store.search.enabled = val
        if (val === false) {
          $store.search.keywords = null
          root.$store.dispatch('tools/emailSpy/fetchVersions')
        }
      },
    })

    const searchKeyword = computed({
      get() {
        return $store.search.keywords
      },
      set(val) {
        $store.search.keywords = val
      },
    })

    const colors = reactive({
      unused: ['primary'],
      used: [],
    })

    return {
      searchEnabled,
      searchKeyword,
      starredMails,
      emailVersions,
      loading,
      activeEmail,
      icons: {
        mdiMagnify,
        mdiArrowLeft,
        mdiStar,
        mdiOpenInNew,
        mdiShareVariant,
        mdiClose,
        mdiStarOutline,
      },

      resolveColor: (pageId, key) => {
        // for active page , for first item show it green other red
        if (activeEmail.value && key === 0) return '#00FF00'
        if (activeEmail.value) return '#FF0000'

        if (colors.used.length && colors.used.find(item => item.pageId === pageId)) {
          return colors.used.find(item => item.pageId === pageId).color
        }
        if (colors.unused.length) {
          colors.used.push({
            pageId,
            color: colors.unused.pop(),
          })
        } else {
          colors.unused = ['primary']

          colors.used.push({
            pageId,
            color: colors.unused.pop(),
          })
        }

        return colors.used.find(item => item.pageId === pageId).color
      },
      resolveIframeURL(versionId) {
        return `${process.env.VUE_APP_API}spy-email/version-iframe/${versionId}`
      },
      ...mapActions('tools/emailSpy', ['setActiveEmail', 'fetchMoreVersions', 'fetchVersions', 'starredSpyEmail']),
    }
  },

  async created() {
    await this.$store.dispatch('tools/emailSpy/fetchVersions')
  },
}
</script>
<style scoped>
.card__title {
  background: #f9fafc;
}
.search__container {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
<style >
.preview__image {
  overflow: hidden;
  /* min-height: 200px; */
}
.v-input fieldset {
  border: none;
}
</style>
