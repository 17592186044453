<template>
  <v-dialog
    v-model="createDialog"
    height="90%"
    width="600px"
  >
    <v-card v-if="monitoriedEmail">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon
          @click="createDialogTrigger"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-actions
        class="flex-column"
      >
        <v-alert
          type="success"
          color="cyan"
          border="top"
          elevation="2"
          colored-border
          class="pt-8"
        >
          Your monitoring is active!
          Please sign up to the service you want to monitor with this email address:
          <div class="mb-3 mt-5">
            <v-btn
              outlined
              small
              dense
              icon
              color="primary"
              @click="copyToClipboard(monitoriedEmail)"
            >
              <v-icon size="14">
                {{ icons.mdiContentCopy }}
              </v-icon>
            </v-btn> {{ monitoriedEmail }}
          </div>
          <div class="w-100 text-end">
            <v-btn
              color="error"
              outlined
              type="button"
              @click="createDialogTrigger"
            >
              Close
            </v-btn>
          </div>
        </v-alert>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <h2 class="headline">
          <v-icon
            size="44"
            color="primary"
            class="mr-2"
          >
            {{ icons.mdiPlusCircleOutline }}
          </v-icon>  Add New Email
        </h2>
        <v-spacer></v-spacer>
        <v-icon
          @click="createDialogTrigger"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <form
        ref="createForm"
        @submit.prevent="submitHandler"
      >
        <v-card-text>
          <v-alert
            v-if="requestError"
            color="error"
            text
            dark
            class="my-4"
          >
            {{ requestError }}
          </v-alert>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Give the name"
              ></v-text-field>

              <v-text-field
                v-model="url"
                label="Specify a website URL"
                :rules="[validators.required]"
                :disabled="existingEmail.alert"
              ></v-text-field>

              <v-select
                v-model="type"
                label="Workflow"
                :items="['Kind of email','Signup flow','Demo Request']"
                :disabled="existingEmail.alert"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              <span class="regular-12 text-uppercase">Notifications</span>
              <div
                class="notifications mt-4"
              >
                <div
                  class="item"
                  :class="notificationSource == 'email' ? 'active' : ''"
                  @click="notificationSource = 'email'"
                >
                  <img
                    :src="require('@/assets/images/svg/mail-icon.svg')"
                  />
                  <span class="pr-3">Email</span>
                </div>
                <div
                  class="item"
                  :class="notificationSource == 'slack' ? 'active' : ''"
                  @click="notificationSource = 'slack'"
                >
                  <img
                    :src="require('@/assets/images/svg/slack-logo.svg')"
                  />
                  <span class="pr-3">Salck</span>
                </div>
              </div>

              <div
                v-if="notificationSource != ''"
                class="notification_inputs pa-3"
              >
                <div
                  v-if="notificationSource == 'email'"
                >
                  <label class="mb-0">To account holder:</label>
                  <v-combobox
                    v-model="state.emails"
                    class="mt-0"
                    placeholder="Email"
                    multiple
                    clearable
                    chips
                    hint="Press enter to add"
                    append-icon=""
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>

                <v-text-field
                  v-else-if="notificationSource == 'slack'"
                  v-model="state.slackHook"
                  label="Slack Webhook URL"
                  placeholder="http://hook-url.example.com"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="existingEmail.alert">
          <div>
            <v-alert
              outlined
              type="info"
            >
              This flow already exists do you want to add the existing one or create a new one?
            </v-alert>

            <v-btn
              class="letter-spacing-sm regular-14 "
              color="primary"
              :loading="loading"
              type="button"
              @click="existingEmail.type = 'new';submitHandler()"
            >
              Create New
            </v-btn>

            <v-btn
              class="letter-spacing-sm regular-14 mx-1"
              color="info"
              :loading="loading"
              outlined
              type="button"
              @click="existingEmail.type = 'existing';submitHandler()"
            >
              Add Existing
            </v-btn>

            <v-btn
              color="error"
              outlined
              type="button"
              @click="existingEmail.alert = null"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-actions>

        <v-card-actions v-else>
          <v-btn
            class="letter-spacing-sm regular-14"
            color="primary"
            :loading="loading"
            type="submit"
          >
            Save
          </v-btn>
          <v-btn
            color="error"
            outlined
            type="button"
            @click="createDialogTrigger"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line import/order
import apiClient from '@/services'
import { required } from '@core/utils/validation'
import { mdiClose, mdiContentCopy, mdiLink, mdiPlus, mdiPlusCircleOutline } from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default {
  setup(props, { root }) {
    const $store = root.$store.state.tools.emailSpy
    const loading = ref(false)
    const notificationSource = ref('')
    const url = ref(null)
    const name = ref(null)
    const type = ref(null)

    const monitoriedEmail = computed(() => $store.monitoriedEmail)
    const createForm = ref(null)
    const existingEmail = reactive({
      alert: false,
      type: null,
    })

    const createDialog = computed({
      get: () => $store.dialogs.create,
      set: () => root.$store.dispatch('tools/emailSpy/createDialogTrigger'),
    })

    const state = {
      emails: [],
      slackHook: null,
    }

    const requestError = ref('')

    const submitHandler = () => {
      // const isFormValid = createForm.value.validate()
      // if (!isFormValid) return

      loading.value = true

      requestError.value = ''
      const payload = {
        emails: state.emails,
        slack_webhook: state.slackHook,
        url: url.value,
        type: type.value,
        name: name.value,
        existing_email: existingEmail.type,
      }

      apiClient
        .storeSpyEmail(payload)
        .then(response => {
          root.$store.dispatch('tools/emailSpy/fetchEmails')
          root.$store.dispatch('notify', response.data.message || 'Email added to monitoring successfully!')

          url.value = null
          name.value = null
          type.value = null
          state.emails = []
          state.slackHook = null
          existingEmail.alert = false
          existingEmail.type = null

          root.$store.dispatch('tools/emailSpy/setMonitoredEmail', response.data.email)
        })
        .catch(err => {
          if (err.response.data.existing_alert) {
            existingEmail.alert = true
          }
          const errorMessage = err.response.data.error || err.response.data.message
          root.$store.dispatch('notify', `Error : ${errorMessage}`)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      createForm,
      url,
      name,
      type,
      createDialog,
      submitHandler,
      existingEmail,
      monitoriedEmail,
      loading,
      requestError,
      ...mapActions('tools/emailSpy', ['createDialogTrigger', 'fetchEmails']),
      state,
      notificationSource,
      icons: {
        mdiPlusCircleOutline,
        mdiClose,
        mdiLink,
        mdiPlus,
        mdiContentCopy,
      },
      validators: {
        required,
      },
      copyToClipboard: text => {
        navigator.clipboard.writeText(text)
        root.$store.dispatch('notify', 'Email copied to clipboard')
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/page-diff/page-form.scss';
</style>
