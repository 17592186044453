<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <img
          :src="require('@/assets/images/logos/email-spy-tool-logo.png')"
          width="50"
        >
        <h3 class="heading mx-2">
          Email Monitoring
        </h3>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col cols="12">
            <!-- header -->
            <div class="d-flex flex-wrap align-center">
              <h4 class="sub__heading mr-15">
                Monitored Emails ({{ emails ? emails.length : 0 }})
              </h4>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="primary"
                @click="createDialogTrigger"
              >
                <v-icon class="m-0">
                  {{ icons.mdiPlus }}
                </v-icon>
                Add New Email
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <content-loader
              v-if="loading"
              primary-color="white"
            >
              <rect
                x="0"
                y="0"
                rx="3"
                ry="3"
                width="100%"
                height="48"
              />

              <rect
                x="0"
                y="55"
                rx="3"
                ry="3"
                width="100%"
                height="48"
              />
            </content-loader>
            <div v-else>
              <v-card
                class="mb-2  py-0 page__container"
                :class="starredMails ? 'active__page' : ''"
                @click="fetchStarredVersions"
              >
                <v-card-text class="py-4 d-flex align-center page">
                  <div class="starred__icon pa-2 ml-1 mr-5">
                    <v-icon
                      size="26"
                      color="white"
                    >
                      {{ icons.mdiStarOutline }}
                    </v-icon>
                  </div>

                  <h3 class="page__title">
                    All Starred Emails
                  </h3>
                </v-card-text>
                <div
                  v-if="starredMails"
                  class="active__border"
                >
                  <v-icon color="white">
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                </div>
              </v-card>
              <email-list-item
                v-for="(email,index) in emails"
                :key="index"
                :email="email"
                :edit-page="editPage"
                :delete-confirmation="deleteConfirmation"
              ></email-list-item>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        sm="12"
        md="6"
      >
        <ActivityTimeline />
      </v-col>
    </v-row>
    <NewEmailDialog />
    <DeleteEmailDialog
      v-if="dialogs.selected"
      :delete-dialog.sync="dialogs.delete"
      :email="dialogs.selected"
    />
    <EditEmailDialog
      v-if="dialogs.selected && dialogs.edit"
      :dialog.sync="dialogs.edit"
      :email="dialogs.selected"
    />
  </div>
</template>
<script>
import {
  mdiChevronRight,
  mdiClock,
  mdiDotsVertical,
  mdiMagnify,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiRefreshCircle,
  mdiStarOutline,
  mdiWeb,
} from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'
import { ContentLoader } from 'vue-content-loader'
import { mapActions } from 'vuex'
import ActivityTimeline from './components/ActivityTimeline.vue'
import DeleteEmailDialog from './components/DeleteEmailDialog.vue'
import EditEmailDialog from './components/EditEmailDialog.vue'
import EmailListItem from './components/EmailListItem.vue'
import NewEmailDialog from './components/NewEmailDialog.vue'

export default {
  components: {
    ActivityTimeline,
    NewEmailDialog,
    EmailListItem,
    ContentLoader,
    DeleteEmailDialog,
    EditEmailDialog,
  },

  async created() {
    await this.$store.dispatch('tools/emailSpy/fetchEmails')
  },

  setup(props, { root }) {
    const $store = root.$store.state.tools.emailSpy
    const starredMails = computed(() => $store.starredMails)
    const emails = computed(() => $store.emails)
    const loading = computed(() => $store.loading.default)

    const searchField = ref('')

    const dialogs = reactive({
      delete: false,
      edit: false,
      selected: null,
    })

    const icons = {
      mdiRefreshCircle,
      mdiPlusCircleOutline,
      mdiMagnify,
      mdiClock,
      mdiWeb,
      mdiDotsVertical,
      mdiPlus,
      mdiStarOutline,
      mdiChevronRight,
    }

    return {
      emails,
      icons,
      loading,
      starredMails,
      dialogs,
      searchField,
      deleteConfirmation: item => {
        dialogs.edit = false
        dialogs.delete = true
        dialogs.selected = item
      },
      editPage: item => {
        dialogs.edit = true
        dialogs.selected = item
      },
      ...mapActions('tools/emailSpy', ['createDialogTrigger', 'fetchEmails', 'fetchStarredVersions']),
    }
  },
}
</script>
<style scoped lang="scss">
.min-height-400 {
  min-height: 359px;
}
.card--title {
  background: #f4f5fa;
}
.starred__icon {
  background: #c81043;
  border-radius: 50%;
}
.page__container {
  display: flex;

  .active__border {
    width: 30px;
    background: #c81043;
    border: 1px solid #c81043;
    display: flex;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .page {
    cursor: pointer;
    .page__title {
      color: rgba(58, 53, 65, 0.87);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.15px;
    }
  }
}

.active__page {
  border: 1px solid #c81043;
  box-shadow: none !important;
}
</style>
