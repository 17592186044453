<template>
  <div class="pa-5 text-center">
    <v-img
      width="400"
      class="ma-auto"
      :src="require('@/assets/images/page-diff/no-activity.png')"
    ></v-img>
    <h4 class="mt-3 medium-20">
      You have not received any mail yet
    </h4>
    <v-card-text class="pa-6 pt-2 regular-14">
      Here you will see emails of all monitored mailing addresses.
    </v-card-text>
  </div>
</template>
<script>
import { mdiPlus } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiPlus,
      },
    }
  },

  methods: {},
}
</script>
<style>
.border__dashed {
  border: 1px dashed rgba(58, 53, 65, 0.23);
  border-radius: 6px;
}
.text-primary {
  color: #c61044;
}

.small__btn {
  min-width: 10px !important;
  width: 10px !important;
}
.w-50 {
  width: 50%;
}

.regular-14 {
  font-weight: normal;
  font-size: 14px;
}
.medium-20 {
  font-weight: 500;
  font-size: 20px;
}
</style>
