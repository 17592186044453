<template>
  <v-dialog
    v-model="editDialog"
    height="90%"
    width="600px"
  >
    <v-card>
      <v-card-title>
        <h2 class="headline">
          <v-icon
            size="44"
            color="primary"
            class="mr-2"
          >
            {{ icons.mdiPlusCircleOutline }}
          </v-icon>  Edit Email
        </h2>
        <v-spacer></v-spacer>
        <v-icon
          @click="$emit('update:dialog',false)"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <form
        ref="createForm"
        @submit.prevent="submitHandler"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="state.name"
                label="Give the name"
              ></v-text-field>

              <v-text-field
                v-model="email.url"
                label="Specify a website URL"
                :rules="[validators.required]"
                :disabled="true"
              ></v-text-field>

              <v-select
                v-model="email.workflow"
                label="Workflow"
                :items="['Kind of email','Signup flow','Demo Request']"
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              <span class="regular-12 text-uppercase">Notifications</span>
              <div
                class="notifications mt-4"
              >
                <div
                  class="item"
                  :class="notificationSource == 'email' ? 'active' : ''"
                  @click="notificationSource = 'email'"
                >
                  <img
                    :src="require('@/assets/images/svg/mail-icon.svg')"
                  />
                  <span class="pr-3">Email</span>
                </div>
                <div
                  class="item"
                  :class="notificationSource == 'slack' ? 'active' : ''"
                  @click="notificationSource = 'slack'"
                >
                  <img
                    :src="require('@/assets/images/svg/slack-logo.svg')"
                  />
                  <span class="pr-3">Salck</span>
                </div>
              </div>

              <div
                v-if="notificationSource != ''"
                class="notification_inputs pa-3"
              >
                <div
                  v-if="notificationSource == 'email'"
                >
                  <label class="mb-0">To account holder:</label>
                  <v-combobox
                    v-model="state.emails"
                    class="mt-0"
                    placeholder="Email"
                    multiple
                    clearable
                    chips
                    hint="Press enter to add"
                    append-icon=""
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>

                <v-text-field
                  v-else-if="notificationSource == 'slack'"
                  v-model="state.slackHook"
                  label="Slack Webhook URL"
                  placeholder="http://hook-url.example.com"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="letter-spacing-sm regular-14"
            color="primary"
            :loading="loading"
            type="submit"
          >
            Update
          </v-btn>
          <v-btn
            color="error"
            outlined
            type="button"
            @click="$emit('update:dialog',false)"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line import/order
import apiClient from '@/services'
import { required } from '@core/utils/validation'
import { mdiClose, mdiLink, mdiPlus, mdiPlusCircleOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    email: {
      type: Object,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const { email } = props

    const loading = ref(false)
    const notificationSource = ref('')
    const state = {
      emails: [],
      slackHook: null,
      name: email.pivot.name,
    }

    if (email.pivot.notification_emails && email.pivot.notification_emails.length) {
      notificationSource.value = 'email'
      state.emails = email.pivot.notification_emails
    }

    if (email.pivot.notification_slack_hook) {
      state.slackHook = email.pivot.notification_slack_hook
    }

    const editForm = ref(null)

    const editDialog = computed({
      set: () => {
        emit('update:dialog', false)
      },
      get: () => props.dialog,
    })

    const submitHandler = () => {
      // const isFormValid = createForm.value.validate()
      // if (!isFormValid) return

      loading.value = true

      const payload = {
        emails: state.emails,
        slack_webhook: state.slackHook,
        name: state.name,
        id: email.id,
      }

      apiClient
        .updateSpyEmail(payload)
        .then(response => {
          root.$store.dispatch('tools/emailSpy/fetchEmails')
          root.$store.dispatch('notify', response.data.message || 'Email updated successfully!')
        })
        .catch(err => {
          const errorMessage = err.response.data.error || err.response.data.message
          root.$store.dispatch('notify', `Error : ${errorMessage}`)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      editForm,
      editDialog,
      submitHandler,
      loading,
      state,
      notificationSource,
      icons: {
        mdiPlusCircleOutline,
        mdiClose,
        mdiLink,
        mdiPlus,
      },
      validators: {
        required,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/page-diff/page-form.scss';
</style>
